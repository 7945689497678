.navbar_input {
	padding: 2px 35px 2px 15px;
	position: relative;
	height: 39px;
	width: 100%;
	border-radius: 3px 0 0 3px;
	border: 0vh;
	font-size: 15px;
	background-color: $ml-white;
}

.logo {
	background-image: url("../../src/assets/icons/Logo_ML.png");
	background-repeat: no-repeat;
	height: 35px;
	width: 53px;
	margin-right: 10px;
}

.navbar_top {
	justify-content: center;
	background-color: $brand-color;
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
}

.input_search {
	overflow: auto;
	justify-content: space-between;
	display: flex;
	flex: 0.5;
	max-width: 820px;
	height: 53px;
	align-items: center;
}

.navbar_input {
    border:0;
    outline: none;
}

.btn_search {
    position: relative;
    width: 45px;
    height: 44px;
    outline: none;
    background-color: $ml-gray;
    border: 0;
    border-radius: 0px 4px 4px 0px;
    font-size: 14px;
    padding: 3px 30px 3px 30px;
    cursor: pointer;
}

.icon_search {
    background-image: url('../../src/assets/icons/ic_Search.png');
    position: absolute;
    height: 18px;
    width: 18px;
    right: 20px;
    top: 14px;
    
 
}

@media (max-width: 768px) {

    .nav-search-btn {
      display: none;
    }
  
    .nav-search-input {
      border-radius: 3px;
    }
  
    .nav-search {
      flex: 0.8;
    }
  }
  
