// Mercadolibre Brands
$font-size: 14px;
$font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$brand-color: #FFF159;

// Secondary Colors
$ml-gray:#EBEBEB;
$ml-white:#FFFFFF;
$ml-black:#000000;
$ml-blue: #5F91D9;
$ml-gray-text:#777777;


/*Note: This colors were taken from the Mercadolibre official site with the What font and 
Color picker extensions. */